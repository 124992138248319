<template>
	<div class="material-box">
		<div class="material">
			<div class="between material-wezhibox">
				<div class="align-center" style="color: #c8c8c8">
					<i class="el-icon-location-outline"></i>
					当前位置：首页> 品牌活动 >{{detali.title}}
				</div>
				
			</div>
			<div class="headers">
				<div style="width: 100%;text-align: center;font-weight: bold;font-size: 19px;">{{detali.title}}</div>
				<div style="margin-top: 20px;">{{detali.ctime}}</div>
			</div>
			<div style="width:100%" class="footer-img" >
				<div style="line_height:24px;width:100%;word-wrap:break-word;padding: 0 30px;word-break: normal;" v-html="detali.content"></div>
			</div>
			
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				SelectTab: 1,
				detali: {
					title: "关羽铜坐像",
					img: require("@/assets/test/test_Icon12.png"),
					brand_code: "活动编号",
					tuijian: "推荐位二",
					start_time: "开始时间",
					end_time: "结束时间",
					status: 0,
					content: `简介:关于铜坐像是韩国最初的Full 3D网络游戏,在2001年国内上市以后,随后在中国,台湾,泰国等亚洲各地区连续上线,引起了巨大的韩潮.成为了网禅的代表作.该游戏凭借'2001大韩民国游戏大奖'的优秀奖(网络部门)'和特别奖(Graphic部门)',获得了对游戏性的认证,次年，‘第二届Digital Innovation大奖'中获得′综`,
					pic_content:'',
				},
				detailimg: require("@/assets/test/test_Icon13.png"),
				detailimg1: "http://bqss-oss.oss-cn-hangzhou.aliyuncs.com/ipjy/IPJY/PC_img/detailimg.png",
			};
		},
		async created() {
			//console.log(this.$route.query);
			const that = this;
			const {
				data
			} = await this.$api({
				c: "index",
				a: "activity_detail",
				id: this.$route.query.id
			});
			//console.log(data);
			var arr = data.re;
			that.detali.title = arr.title;
			that.detali.img = arr.image;
			that.detali.is_look = arr.is_look;
			that.detali.brand_code = arr.brand_code; //编码
			if (arr.tuijian == 1) {
				that.detali.tuijian = '推荐位一'; //推荐位
			} else if (arr.tuijian == 2) {
				that.detali.tuijian = '推荐位二'; //推荐位
			} else if (arr.tuijian == 3) {
				that.detali.tuijian = '推荐位三'; //推荐位
			}
			that.detali.start_time = arr.start_time; //开始时间
			that.detali.end_time = arr.end_time; //结束时间
			that.detali.ctime = arr.ctime; //开始时间
			that.detali.address = arr.address; //地址
			var content=arr.content.replace(/<img/g,"<img style='max-width:100%;height:auto;'");
			that.detali.content = content;
			that.detali.status = arr.status;
			var pic_content=arr.pic_content.replace(/<img/g,"<img style='max-width:100%;height:auto;'");
			that.detali.pic_content = pic_content;
		},
		methods: {
			SelectTab1(e) {
				// if (e == 2) {
				//   this.detailimg = this.detailimg1;
				// } else {
				//   this.detailimg = require("@/assets/test/test_Icon13.png");
				// }
				this.SelectTab = e;
			},
			sqSubmit() {
				var brand_code = this.detali.brand_code;
				this.$router.push({
					path: "/index/apply_add",
					query: {
						is_type: 4,
						brand_code: brand_code,
						id: this.$route.query.id,
					},
				});
			},
			
		},
	};
</script>

<style lang="scss" scoped>
	.material-box {
		height: 100%;
		background: rgb(248, 248, 248);
		padding-bottom: 60px;

		.material {
			width: 1200px;
			margin: 0 auto;
			background: #ffffff;
			padding-bottom: 60px;
		}

		.material-wezhibox {
			padding: 14px 15px;
			border-bottom: 1px solid #dfdfdf;
			margin-bottom: 30px;
		}

		.Secrch {
			border-radius: 100px;
			padding: 4px 24px;
			border: 1px solid rgb(0, 118, 254);
			color: rgb(0, 118, 254);

			i {
				margin-right: 5px;
			}
		}
	}

	.tab-list {
		.tab-item {
			width: 100px;
			height: 50px;
			line-height: 50px;
			margin-right: 10px;
			border-bottom: 2px solid #ffffff;
			text-align: center;
		}

		.selectTab_item {
			border-bottom: 2px solid rgb(0, 118, 254);
		}
	}

	.headers {
		padding: 43px 0px 50px 30px;

		.application {
			cursor: pointer;
			color: rgb(0, 118, 254);
			padding: 9px 40px;
			border: 1px solid rgb(0, 118, 254);
			margin: 50px 0;
		}

		.application1 {
			cursor: pointer;
			color: rgb(226, 226, 226);
			padding: 9px 40px;
			border: 1px solid rgb(226, 226, 226);
			margin: 50px 0;
		}

		.right-detail {
			white-space: pre-wrap;
			color: #676767;
			font-size: 13px;
			line-height: 30px;
			display: flex;
			flex-direction: column;

			.detali_titles {
				color: #212121;
				font-size: 18px;
				// margin-bottom: auto;
				margin-bottom: 50px;
			}
		}

		.headers-leftimg {
			margin-right: 40px;
		}
	}

	.footer-img {
		display: flex;
		justify-content: center;

		img {
			object-fit: cover;
		}
	}

	.activity-pz {
		margin-top: 15px;
		width: 100%;
		position: relative;
		margin-bottom: 180px;
	}

	.activity-pz .btn-ac {
		position: absolute;
		top: 0px;
		left: 10px;
	}

	.activity-pz .btn-ac1 {
		position: absolute;
		top: 0px;
		left: 100px;
	}

	.activity-pz .pz-BTNac {
		display: inline-block;
		font-size: 17px;
		margin-left: 10px;
	}

	.activity-pz .pz-BTNac .btn-mfbm {
		background-color: rgb(247, 247, 247, 0.5);
		color: rgb(232, 231, 231);
		line-height: 20px;
		padding: 10px 20px;
		margin-bottom: 10px;
	}

	.activity-pz .pz-BTNac .btn-ltbm {
		color: red;
		margin-bottom: 10px;
	}

	.baoming {
		width: 100%;
		margin-top: 20px;
		
	}

	.baoming .btn-bm {
		border: 1px rgb(82, 118, 254) solid;
		padding: 14px 20px;
		color: rgb(82, 118, 254);
		text-align: center;
		display: inline-block;
		border-radius: 10px;
	}

	.baoming .btn-bm1 {
		border: 1px #c6c6c6 solid;
		padding: 14px 20px;
		color: #c6c6c6;
		text-align: center;
		display: inline-block;
		border-radius: 10px;
	}

	.baoming .btn-ll {
		
		text-align: center;
		display: inline-block;
		margin-left: 150px;
		border: 1px rgb(255, 200, 156) solid;
		color: rgb(255, 200, 156);
		padding: 10px 12px;
		border-radius: 10px;
	}
	.baoming .btn-ll .btn-look{
		display: inline-block;
		float: right;
	
	}
	.btn-lookimg{
		width: 23px;
        height:23px;
		margin-right: 10px;
		display: inline-block;
	}
</style>
